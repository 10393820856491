.map-viewer {
  height: 100%;
  width: 100%;
}

.tooltip_image {
  height: 80px;
  width: 80px;
}

.react-tabs__tab {
  display: inline-block;
  padding: 6px 55px;
  margin: 5px;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #000;
  transition: all 0.5s ease;
  font-size: 1rem;
}

.react-tabs__tab:hover {
  /* background: rgba(145,165,172,0.2); */
  transition: all 0.5s ease;
}

.react-tabs__tab--selected {
  font-weight: bold;
  border-bottom: 5px solid #253e45;
  color: black;
  border-radius: 2px;
  transition: left 0.5s ease;
}

.react-tabs__tab-list {
  border-bottom: none;
  margin: 3px 0 5px;
  padding: 0;
}
