.sidebar {
  position: absolute;
  top: 0px;
  /* left: -15vw; */
  height: 100%;
  width: 8vw;
  transition: left 0.3s ease-in-out;
  background-color: rgb(255, 255, 255);
  border-right: 2px;
  border-style: solid;
  border-color: black;
}
.sidebar-toggle {
  position: absolute;
  top: 2vh;
  right: 1vw;
  height: 20px;
  width: 20px;
  z-index: 1;
  color: black;
  background-color: rgb(0, 0, 0);
}
.content {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  height: 100%;
  transition: left 0.3s ease-in-out;
  background-color: lightgray;
}
.sidebar.open {
  height: 100%;
  left: 0;
  width: 15vw;
}
.content.open {
  left: 15vw;
}

.highlight {
  outline: 2px solid yellow;
}
