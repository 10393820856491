body {
  background: white !important;
  font-family: "Open Sans", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

/* .mod-footer .footer-info-left .social-networks ul li {
  display: inline-block;
  vertical-align: middle;
}

.mod-footer .footer-info-left .social-networks ul li + li {
  margin-left: 10px;
} */

.goog-logo-link,
.goog-te-gadget span {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0;
}

.goog-te-banner-frame {
  display: none !important;
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="password"],
select {
  height: 5vh; 
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 1vh;
  padding-left: 1vh;
}
