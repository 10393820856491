.map-viewer {
  height: 100%;
  width: 100%;
}

.tooltip_image {
  height: 80px;
  width: 80px;
}

.StyledInput{
  border-radius: 6px;
  height: 5vh;
  width: 100%;
}