.map-viewer {
  height: 100%;
  width: 100%;
}

.tooltip_image {
  height: 80px;
  width: 80px;
}
.accordion {
  max-width: 600px;
  min-width: 50vw;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #ed6e34;
  transition: background-color 0.3s;
}

.accordion-title:hover {
  background-color: #e0e0e0;
}

.accordion-title.active {
  background-color: #ed6e34;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #ed8a34;
}

/* @media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  } */

.accordion {
  width: 90%;
}
